<template>
  <div class="organization-viewer" v-if="organization !== null">
    <h1 class="title" v-if="organization.name !== null">{{ organization.name }}</h1>
    <h1 class="title" v-else>Unnamed organization</h1>
    <h4 class="sub-name ubuntu">{{ organization.unique }}</h4>
    <grid>
      <grid-col size="8" v-if="currentView === 'overview'">
        <card title="Stats">
          <TextProperty
            v-if="devicesTotal !== null"
            title="Devices Total"
            :value="devicesTotal.toString()"
          />
          <TextProperty
            v-if="devicesApproved !== null"
            title="Approved Devices"
            :value="devicesApproved.toString()"
          />
          <TextProperty
            v-if="devicesUnapproved !== null"
            title="Unapproved Devices"
            :value="devicesUnapproved.toString()"
          />
          <TextProperty
            v-if="devicesOnline !== null"
            title="Devices Online"
            :value="devicesOnline.toString()"
          />
          <TextProperty
            v-if="triggersTotal !== null"
            title="Active alerts for devices"
            :value="triggersTotal.toString()"
          />
          <TextProperty v-if="usersTotal !== null" title="Users" :value="usersTotal.toString()" />
        </card>
        <div v-if="currentView === 'overview' && userCan('view', 'bearertoken')">
          <list-tokens :orgId="organization._id" />
        </div>
      </grid-col>
      <grid-col size="4" v-if="currentView === 'overview'">
        <ListUsers
          :orgId="organization._id"
          :users="organization.users"
          v-on:userEdit="handleUserEdit"
        />
      </grid-col>
    </grid>
    <NewUser v-if="currentView === 'new-user'"
      v-on:afterEdit="afterEdit"
      :user="user"
      :orgId="organization._id" />
    <div v-if="currentView === 'edit-user'">
      <EditUser
        :user="user"
        :orgId="organization._id"
        v-on:afterEdit="afterEdit"
      />
    </div>
    <NewToken v-if="currentView === 'new-token'"
      v-on:afterEdit="afterEdit"
      :orgId="organization._id" />
    <div v-if="currentView === 'edit-token'">
      <EditToken
        :orgId="organization._id"
        v-on:afterEdit="afterEdit"
      />
    </div>
  </div>
  <div class="loading" v-else>
    <div class="loader">
      <img :src="loadingImage" />
    </div>
  </div>
</template>

<script>
import Utils from '@/utils';
import Card from '../Card/Card.vue';
import BaseComponent from '../Base.vue';
import TextProperty from '../Property/TextProperty.vue';
import ListUsers from '../Users/list-users.vue';
import NewUser from '../Users/CreateUser.vue';
import EditUser from '../Users/EditUser.vue';
import ListTokens from '../BearerToken/list-tokens.vue';
import NewToken from '../BearerToken/create-token.vue';
import EditToken from '../BearerToken/edit-token.vue';
import Grid from '../Grid/Grid.vue';
import GridCol from '../Grid/GridCol.vue';

export default {
  name: 'singleorganization',
  extends: BaseComponent,
  components: {
    Card,
    TextProperty,
    ListUsers,
    NewUser,
    EditUser,
    ListTokens,
    EditToken,
    NewToken,
    Grid,
    GridCol,
  },
  props: ['organizationId'],
  watch: {
    organizationId(newVal) {
      this.getorganization(newVal);
    },
    $route(to) {
      this.actOnRoute(to);
    },
  },
  data() {
    return {
      ajaxCompleted: false,
      organization: null,
      user: null,
      currentView: 'overview',
      devices: [],
      devicesTotal: null,
      devicesOnline: null,
      devicesUnapproved: null,
      devicesApproved: null,
      usersTotal: null,
      triggersTotal: null,
    };
  },
  methods: {
    actOnRoute(route) {
      if (route.params.user) {
        if (route.params.user === 'new') {
          this.createUser();
        } else {
          this.getuser(route.params.user);
        }
      }
      if (route.params.token) {
        if (route.params.token === 'new') {
          this.currentView = 'new-token';
        } else {
          this.currentView = 'edit-token';
        }
      }

      // Fallback on the overview if no specific route is selected.
      if (!route.params.token && !route.params.user) {
        this.currentView = 'overview';
      }
    },
    handleUserEdit(id) {
      this.editUser = id;
    },
    async getorganization(id) {
      const organizationRes = await Utils.fetch(
        `/api/v1/organizations/${id}`,
        {},
        this,
      ).then((res) => res.json());
      if (organizationRes.success) {
        this.ajaxCompleted = true;
        this.organization = organizationRes.organization;
        this.usersTotal = organizationRes.organization.users.length;

        const response = await Utils.fetch(
          `/api/v1/organizations/${id}/devices`,
          {},
          this,
        ).then((res) => res.json());
        if (response.success) {
          this.devices = response.devices;
          this.devicesTotal = response.devices.length;
          this.triggersTotal = response.triggers;
          this.devicesOnline = response.devices.reduce((total, device) => {
            if (device.online !== false) {
              return total + 1;
            }
            return total;
          }, 0);

          this.devicesUnapproved = response.devices.reduce((total, device) => {
            if (device.approvedSince === null) {
              return total + 1;
            }
            return total;
          }, 0);

          this.devicesApproved = this.devicesTotal - this.devicesUnapproved;
        }
      }
    },
    async getuser(id) {
      if (id !== 'new') {
        const response = await Utils.fetch(
          `/api/v1/organizations/${this.organization._id}/user/${id}`,
        ).then((res) => res.json());
        if (response.success) {
          this.user = response.user;
          this.user.password = '';
          this.user.reset2fa = false;
          this.currentView = 'edit-user';
        }
      }
    },
    afterEdit() {
      this.currentView = 'overview';
      this.user = null;
      this.$router.replace(`/organizations/${this.organization._id}`);
      this.getorganization(this.organizationId);
    },
    createUser() {
      this.user = {
        admin: false,
      };
      this.currentView = 'new-user';
    },
    userCan(...args) {
      return Utils.userCan(...args);
    },
  },
  mounted() {
    this.actOnRoute(this.$route);
    this.getorganization(this.organizationId).then(async () => {
      if (typeof this.$route.params.user !== 'undefined') {
        await this.getuser(this.$route.params.user);
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.title {
  cursor: pointer;
}

.sub-name {
  margin-top: -15px;
}

.actions {
  margin-bottom: 10px;
}

.loader {
  text-align: center;
  padding: 20px 0;

  img {
    height: 30px;
  }
}

.switch {
  margin-top: 6px;
}

.link {
  text-decoration: underline;
  display: inline-block;
  cursor: pointer;

  &.external {
    background-position: center right;
    background-repeat: no-repeat;
    background-image: linear-gradient(transparent, transparent),
      url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2212%22 height=%2212%22%3E %3Cpath fill=%22%233a4149%22 stroke=%22%23fff%22 d=%22M1.5 4.518h5.982V10.5H1.5z%22/%3E %3Cpath fill=%22%233a4149%22 d=%22M5.765 1H11v5.39L9.427 7.937l-1.31-1.31L5.393 9.35l-2.69-2.688 2.81-2.808L4.2 2.544z%22/%3E %3Cpath fill=%22%23fff%22 d=%22M9.995 2.004l.022 4.885L8.2 5.07 5.32 7.95 4.09 6.723l2.882-2.88-1.85-1.852z%22/%3E %3C/svg%3E');
    padding-right: 13px;
  }
}

.card-title {
  color: white;
  font-size: 16px;
  font-weight: bold;
}

.user-list {
  margin-top: 5px;
}

.user-link {
  margin-bottom: 4px;
}

.user-link > a {
  color: #ffffff;
  margin-right: 4px;
  text-decoration: none;
}

.input-text {
  box-sizing: border-box;
  width: 100%;
}
</style>

<template>
  <grid>
    <grid-col size="6">
      <card title="Create new user">
        <div class="properties">
          <div class="property">
            <div class="title-label">First name</div>
            <input
              type="text"
              placeholder="First name"
              class="input-text"
              v-model="editUserData.firstname"
            />
          </div>
          <div class="property">
            <div class="title-label">Last name</div>
            <input
              type="text"
              placeholder="Last name"
              class="input-text"
              v-model="editUserData.lastname"
            />
          </div>
          <div class="property">
            <div class="title-label">E-mail</div>
            <input
              type="text"
              placeholder="fox.mulder@fbi.gov"
              class="input-text"
              v-model="editUserData.email"
            />
          </div>
          <div class="property">
            <div class="title-label">Login</div>
            <input
              type="text"
              placeholder="fox.mulder@fbi.gov"
              class="input-text"
              v-model="editUserData.login"
            />
          </div>
          <div class="property">
            <div class="title-label">New password</div>
            <input
              type="text"
              placeholder="t0ps3cr3t"
              class="input-text"
              v-model="editUserData.password"
            />
          </div>
          <div v-if="isAdmin()" class="property">
            <div class="title-label">Admin</div>
            <input type="checkbox" class="input-text" v-model="editUserData.admin" />
          </div>
          <div v-else class="property">
            <div class="title-label">Admin</div>
            <i class="icon-close red"></i>
          </div>
          <div class="property">
            <base-button v-on:click="storeUser" title="Save" />
            <base-button v-on:click="cancelEdit" type="THEMED" iconType="CANCEL" title="Cancel" />
          </div>
        </div>
      </card>
    </grid-col>
    <grid-col size="6">
      <card title="Access control" />
    </grid-col>
  </grid>
</template>

<script>
import Utils from '@/utils';
import Card from '../Card/Card.vue';
import Grid from '../Grid/Grid.vue';
import GridCol from '../Grid/GridCol.vue';
import BaseButton from '../BaseButton/BaseButton.vue';

export default {
  name: 'CreateUser',
  components: {
    Card,
    Grid,
    GridCol,
    BaseButton,
  },
  props: ['user', 'orgId'],
  data() {
    return {
      editUserData: { ...this.user },
    };
  },
  methods: {
    async storeUser() {
      const url = `/api/v1/organizations/${this.orgId}/user`;
      const response = await Utils.fetch(
        url,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user: this.editUserData,
          }),
        },
        this,
      ).then((res) => res.json());

      this.afterEdit();

      if (response.success) {
        this.$noty.success('The user has been created!');
      } else {
        this.$noty.error('The user has NOT been created!');
      }
    },
    afterEdit() {
      this.$emit('afterEdit');
    },
    cancelEdit() {
      this.afterEdit();
    },
    isAdmin() {
      return Utils.isAdmin();
    },
  },
};
</script>

<template>
  <div class="organization-table">
    <!-- Table for organizations -->
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Unique</th>
          <th>Currently Selected</th>
        </tr>
      </thead>
      <tbody v-if="ajaxCompleted">
        <tr
          v-for="organization in organizations"
          @click="handleRowClick(organization._id)"
          :data-id="organization._id"
          :key="organization._id"
        >
          <td>
            {{ organization.name }}
          </td>
          <td>
            {{ organization.unique }}
          </td>
          <td>
            <span v-if="organization.selected"><i class="uil uil-check" /></span>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="5" class="loader">
            <img :src="loadingImage" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Utils from '@/utils';

export default {
  name: 'organizationTable',
  props: ['events'],
  data() {
    return {
      ajaxCompleted: false,
      organizations: [],
    };
  },
  methods: {
    async getorganizations() {
      const response = await Utils.fetch('/api/v1/access/organizations', {}, this).then((res) =>
        res.json(),
      );
      if (response.success) {
        this.ajaxCompleted = true;
        this.organizations = response.organizations;
      }
    },
    async handleRowClick(id) {
      const rows = document.querySelectorAll('.organization-table tr');
      for (let i = 0; i < rows.length; i += 1) {
        rows[i].classList.remove('selected');
      }
      document.querySelector(`.organization-table tr[data-id="${id}"]`).classList.add('selected');

      const response = await Utils.fetch('/api/v1/access/organizations', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          organization: id,
        }),
      })
        .then((res) => res.json())
        .catch((error) => {
          console.error(error.response);
        });

      if (response.success) {
        this.organizations = this.organizations.map((org) => ({
          ...org,
          selected: org._id === id,
        }));
      }

      this.$emit('rowClick', id);
      this.$router.push(`/organizations/${id}`);
    },
  },
  mounted() {
    this.getorganizations();
  },
};
</script>

<style lang="scss" scoped>
.organization-table {
  overflow-x: auto;
  padding-bottom: 30px;
}

.organization-table table {
  width: 100%;
  border-collapse: collapse;

  .loader {
    text-align: center;
    padding: 20px 0;

    img {
      height: 30px;
    }
  }

  tr {
    height: 30px;
  }

  tbody tr {
    cursor: pointer;

    &.selected {
      background: var(--table-header-color);
    }

    &:hover {
      background: var(--table-header-color);
    }
  }

  th,
  td {
    padding: 0 10px;
  }

  thead tr th {
    //border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    text-align: left;
    background: var(--table-header-color);
  }

  th {
    font-weight: normal;
  }
}

.bullet {
  height: 10px;
  width: 10px;
  display: inline-block;
  border-radius: 100%;

  &.blue {
    background: rgb(88, 195, 220);
  }

  &.green {
    background: #4dbd74;
  }

  &.red {
    background: #f86c6b;
  }
}
</style>

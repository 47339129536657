import { render, staticRenderFns } from "./edit-token.vue?vue&type=template&id=5fd599de&"
import script from "./edit-token.vue?vue&type=script&lang=ts&"
export * from "./edit-token.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.9.6_fb85a9817f99bdf862e5e3a442a1fb91/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

















































import Vue from 'vue';
import Card from '../Card/Card.vue';
import Grid from '../Grid/Grid.vue';
import GridCol from '../Grid/GridCol.vue';
import BaseButton from '../BaseButton/BaseButton.vue';
import Property from '../Util/Property.vue';
import { BearerToken } from '../../models/BearerToken';

export default Vue.extend({
  name: 'CreateUser',
  components: {
    Card,
    Grid,
    GridCol,
    BaseButton,
    Property,
  },
  props: ['user', 'orgId'],
  data() {
    return {
      token: {
        name: '',
        aclRole: '',
      } as BearerToken & {
        name: string;
        aclRole?: string;
      },
      aclRoles: [],
    };
  },
  methods: {
    async editToken() {
      try {
        await this.$utils.http().put(`/api/v1/api-tokens/${this.token._id}`, this.token);
        this.$noty.success('The token has been updated!');
        this.afterEdit();
      } catch (err) {
        this.$noty.error(`Could not create token: ${err.response.data.message}`);
      }
    },
    async getToken() {
      const response = await this.$utils.http().get(`/api/v1/api-tokens/${this.$route.params.token}`);
      this.token = response.data as BearerToken;
      this.token.aclRole = (this.token.roles.length && this.token.roles[0]) || '';
    },
    async getAclRoles() {
      const response = await this.$utils.http().get('/api/v1/api-tokens/roles');
      this.aclRoles = response.data;
    },
    async deleteToken() {
      // eslint-disable-next-line no-restricted-globals, no-alert
      if (confirm('Do you really want to delete this API-token?')) {
        await this.$utils.http().delete(`/api/v1/api-tokens/${this.$route.params.token}`);
        this.afterEdit();
      }
    },
    afterEdit() {
      this.$emit('afterEdit');
    },
    cancelEdit() {
      this.afterEdit();
    },
  },
  mounted() {
    this.getAclRoles();
    this.getToken();
  },
});

<template>
  <div class="organizations">
    <panes>
      <template v-slot:left>
        <div class="organization-list">
          <h1 class="white">Organizations</h1>
          <OrganizationTable v-on:rowClick="handleRowClick" />
        </div>
      </template>
      <template v-slot:right>
        <div class="organization-view">
          <OrganizationRead
            v-if="selected !== null && typeof selected === 'string'"
            :organization-id="selected"
          />
        </div>
      </template>
    </panes>
  </div>
</template>

<script>
import OrganizationTable from '@/components/Table/Organization.vue';
import OrganizationRead from '@/components/Organization/Read.vue';
import Panes from '@/components/Panes/Panes.vue';

export default {
  name: 'Organization',
  components: {
    OrganizationTable,
    OrganizationRead,
    Panes,
  },
  data() {
    return {
      selected: null,
    };
  },
  methods: {
    handleRowClick(id) {
      this.selected = id;
    },
  },
  mounted() {
    if (typeof this.$route.params.organization !== 'undefined') {
      this.selected = this.$route.params.organization;
    }
  },
};
</script>

<style lang="scss" scoped>
.organizations {
  height: 100%;
}
.organization-list {
  height: 100%;
}

.organization-view {
  height: 100%;
  overflow-x: auto;
}
</style>

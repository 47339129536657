
































import Vue from 'vue';
import Card from '../Card/Card.vue';
import Grid from '../Grid/Grid.vue';
import GridCol from '../Grid/GridCol.vue';
import BaseButton from '../BaseButton/BaseButton.vue';

export default Vue.extend({
  name: 'CreateUser',
  components: {
    Card,
    Grid,
    GridCol,
    BaseButton,
  },
  props: ['user', 'orgId'],
  data() {
    return {
      token: {
        name: '',
        aclRole: '',
      },
      aclRoles: [],
    };
  },
  methods: {
    async createToken() {
      try {
        const response = await this.$utils.http().post('/api/v1/api-tokens', this.token);
        this.$noty.success('The token has been created!');
        this.$router.push(`/organizations/${this.orgId}/token/${response.data._id}`);
      } catch (err) {
        this.$noty.error(`Could not create token: ${err.response.data.message}`);
      }
    },
    async getAclRoles() {
      const response = await this.$utils.http().get('/api/v1/api-tokens/roles');
      this.aclRoles = response.data;
    },
    afterEdit() {
      this.$emit('afterEdit');
    },
    cancelEdit() {
      this.afterEdit();
    },
  },
  mounted() {
    this.getAclRoles();
  },
});

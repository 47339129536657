<template>
  <div>
    <div v-show="roles">
      <div class="role">
        <div>Role:</div>
        <div>
          <div v-for="roleRow in roles" :key="roleRow" class="role-row">
            <label>
              <input
                type="radio"
                name="role"
                :value="roleRow"
                v-model="role"
                @input="handleInput"
              />
              {{ capitalize(roleRow) }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Utils from '@/utils';

export default {
  name: 'AccessControl',
  props: ['value', 'edituserid'],
  data() {
    return {
      role: 'guest',
      roles: null,
    };
  },
  watch: {
    value(newVal) {
      if (typeof newVal !== 'undefined') {
        this.role = newVal;
      }
    },
  },
  methods: {
    async getAccessControlForUser() {
      const result = await Utils.fetch(
        `/api/v1/organizations/roles/${this.edituserid}`,
        {},
        this,
      ).then((res) => res.json());
      if (result.success) {
        this.roles = result.allRoles;

        if (result.userRoles.length === 1) {
          [this.role] = result.userRoles;
        }
      }
    },
    capitalize(s) {
      if (typeof s !== 'string') return '';
      return s.charAt(0).toUpperCase() + s.slice(1);
    },
    handleInput(e) {
      this.$emit('input', e.target.value);
    },
  },
  mounted() {
    this.getAccessControlForUser();
    if (typeof this.value === 'string') {
      this.role = this.value;
    } else {
      this.role = 'guest';
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.role {
  color: white;
}

.role-row {
  padding: 2px 0;
}
</style>
